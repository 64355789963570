import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UiState } from '@marketplace-web/shared/ui-helpers'
import { SystemConfigurationModel } from 'types/models'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  requestState: UiState.Idle,
}

const fetchSystemConfigurationRequest: CaseReducer<State> = draft => {
  draft.requestState = UiState.Pending
}

const fetchSystemConfigurationSuccess: CaseReducer<
  State,
  PayloadAction<{ systemConfiguration: SystemConfigurationModel }>
> = (draft, action) => {
  draft.systemConfiguration = action.payload.systemConfiguration
  draft.requestState = UiState.Success
}

const fetchSystemConfigurationFailure: CaseReducer<State> = draft => {
  draft.requestState = UiState.Failure
}

const systemConfigurationSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchSystemConfigurationRequest,
    fetchSystemConfigurationSuccess,
    fetchSystemConfigurationFailure,
  },
})

export const { actions } = systemConfigurationSlice
export const plug = { [stateName]: systemConfigurationSlice.reducer }
export default systemConfigurationSlice.reducer

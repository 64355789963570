import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UiState } from '@marketplace-web/shared/ui-helpers'
import { ConversationInitiatorType } from 'constants/conversation'
import { HttpStatus } from 'data/api/response-codes'
import { ReactionModel, TransactionModel } from 'types/models'

import { stateName } from './constants'
import { Conversation, Message, State } from './types'

export const initialState: State = {
  conversation: undefined,
  ui: {
    sendMessage: { error: null, uiState: UiState.Idle },
    getTransaction: { error: null, uiState: UiState.Idle },
    getConversation: { error: null, uiState: UiState.Idle, status: null },
    harassmentWarning: {
      isModalOpen: false,
      wasModalOpen: false,
      scoredMessage: null,
      scoredHarassmentMessageCorrelationId: null,
    },
    isEmailSharingModalVisible: false,
    isFirstTimeListerEducationModalVisible: false,
  },
  transaction: null,
}

const getConversationSuccess: CaseReducer<
  State,
  PayloadAction<{ conversation: Conversation; status: HttpStatus }>
> = (draft, action) => {
  const { conversation, status } = action.payload

  draft.conversation = conversation
  draft.ui.getConversation.uiState = UiState.Success
  draft.ui.getConversation.status = status
}

const getConversationUiPending: CaseReducer<State, PayloadAction<{ fromPostReply?: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.getConversation.uiState = UiState.Pending
  draft.ui.getConversation.status = null

  if (!action.payload.fromPostReply) {
    draft.conversation = undefined
  }
}

const getConversationUiFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null; status: HttpStatus | null }>
> = (draft, action) => {
  const { error, status } = action.payload

  draft.conversation = undefined
  draft.ui.getConversation.error = error
  draft.ui.getConversation.uiState = UiState.Failure
  draft.ui.getConversation.status = status
}

const clearConversation: CaseReducer<State> = draft => {
  draft.conversation = undefined
  draft.ui.getConversation.status = null
  draft.ui.getConversation.uiState = UiState.Idle
  draft.ui.getConversation.error = null
}

const setConversation: CaseReducer<State, PayloadAction<{ conversation: Conversation }>> = (
  draft,
  action,
) => {
  const { conversation } = action.payload

  draft.conversation = conversation
  draft.ui.getConversation.status = HttpStatus.Ok
  draft.ui.getConversation.uiState = UiState.Success
  draft.ui.getConversation.error = null
}

const postReplyRequest: CaseReducer<
  State,
  PayloadAction<{
    value: string | null | undefined
    photoTempUuids?: Array<string> | null
    isPersonalDataSharingCheckSkipped?: boolean
  }>
> = draft => {
  draft.ui.sendMessage.uiState = UiState.Pending
}

const postReplySuccess: CaseReducer<State> = draft => {
  draft.ui.sendMessage.uiState = UiState.Success
  draft.ui.sendMessage.error = null
}

const setSendMessageUiFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.sendMessage.error = error
  draft.ui.sendMessage.uiState = UiState.Failure
}

const createItemConversationThreadRequest: CaseReducer<
  State,
  PayloadAction<{
    itemId: string
    sellerId: string
    initiator: ConversationInitiatorType
  }>
> = draft => {
  draft.ui.getConversation.uiState = UiState.Pending
}

const createItemConversationThreadFailure: CaseReducer<
  State,
  PayloadAction<{
    error: string | null
  }>
> = (draft, action) => {
  draft.ui.getConversation.uiState = UiState.Failure
  draft.ui.getConversation.error = action.payload.error
}

const createItemConversationThreadSuccess: CaseReducer<
  State,
  PayloadAction<{
    conversation: Conversation
  }>
> = (draft, actions) => {
  draft.conversation = actions.payload.conversation
  draft.ui.getConversation.uiState = UiState.Success
}

const createConversationThreadRequest: CaseReducer<
  State,
  PayloadAction<{
    recipientId: number | null | undefined
    value: string | null | undefined
    photoTempUuids?: Array<string> | null
  }>
> = draft => {
  draft.ui.sendMessage.uiState = UiState.Pending
}

const setMessageDeleted: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number }>
> = (draft, action) => {
  const { messageId, photoId } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => (message as Message).id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.url = ''
    photo.isHidden = true
  } else {
    message.isHidden = true
    message.content = ''
  }
}

const setMessageLiked: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number; reaction: ReactionModel }>
> = (draft, action) => {
  const { messageId, photoId, reaction } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => message && 'id' in message && message.id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.reaction = {
      id: reaction.id,
      type: reaction.type,
    }

    return
  }

  message.reaction = {
    id: reaction.id,
    type: reaction.type,
  }
}

const setMessageUnliked: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number; reactionId: number }>
> = (draft, action) => {
  const { messageId, photoId } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => message && 'id' in message && message.id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.reaction = null

    return
  }

  message.reaction = null
}

const setHarassmentMessage: CaseReducer<
  State,
  PayloadAction<{ messageText: string | null; correlationId: string | null }>
> = (draft, action) => {
  const { messageText, correlationId } = action.payload

  draft.ui.sendMessage.uiState = UiState.Failure
  draft.ui.harassmentWarning.isModalOpen = true
  draft.ui.harassmentWarning.wasModalOpen = true
  draft.ui.harassmentWarning.scoredMessage = messageText
  draft.ui.harassmentWarning.scoredHarassmentMessageCorrelationId = correlationId
}

const clearHarassmentMessage: CaseReducer<State> = draft => {
  draft.ui.harassmentWarning.isModalOpen = false
  draft.ui.harassmentWarning.wasModalOpen = false
  draft.ui.harassmentWarning.scoredMessage = null
}

const setHarassmentWarningModalClosed: CaseReducer<State> = draft => {
  draft.ui.harassmentWarning.isModalOpen = false
}

const setEmailSharingModalVisible: CaseReducer<State, PayloadAction<{ isVisible: boolean }>> = (
  draft,
  action,
) => {
  const { isVisible } = action.payload

  draft.ui.isEmailSharingModalVisible = isVisible
}

const fetchTransactionRequest: CaseReducer<
  State,
  PayloadAction<{
    transactionId: number
  }>
> = draft => {
  draft.ui.getTransaction.uiState = UiState.Pending
}

const fetchTransactionRequestSuccess: CaseReducer<
  State,
  PayloadAction<{ transaction: TransactionModel }>
> = (draft, action) => {
  const { transaction } = action.payload

  draft.transaction = transaction

  draft.ui.getTransaction.uiState = UiState.Success
  draft.ui.getTransaction.error = null
}

const fetchTransactionRequestFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.getTransaction.uiState = UiState.Failure
  draft.ui.getTransaction.error = error
}

const setFirstTimeListerEducationModalVisible: CaseReducer<
  State,
  PayloadAction<{ isVisible: boolean }>
> = (draft, action) => {
  const { isVisible } = action.payload

  draft.ui.isFirstTimeListerEducationModalVisible = isVisible
}

const conversationSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getConversationSuccess,
    getConversationUiFailure,
    getConversationUiPending,
    clearConversation,
    setConversation,
    /** @deprecated Use usePostReply to access this action */
    postReplyRequest,
    postReplyFailure: setSendMessageUiFailure,
    postReplySuccess,
    createConversationThreadRequest,
    createConversationThreadFailure: setSendMessageUiFailure,
    setMessageDeleted,
    setMessageLiked,
    setMessageUnliked,
    setHarassmentMessage,
    clearHarassmentMessage,
    setHarassmentWarningModalClosed,
    setEmailSharingModalVisible,
    createItemConversationThreadRequest,
    createItemConversationThreadSuccess,
    createItemConversationThreadFailure,
    fetchTransactionRequest,
    fetchTransactionRequestSuccess,
    fetchTransactionRequestFailure,
    setFirstTimeListerEducationModalVisible,
  },
})

export const { actions } = conversationSlice
export const plug = { [stateName]: conversationSlice.reducer }
export default conversationSlice.reducer

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UiState } from '@marketplace-web/shared/ui-helpers'
import { BannersModel } from 'types/models'
import { transformBanners } from 'data/transformers/banners'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  banners: transformBanners({}),
  uiState: UiState.Idle,
}

const changeUiState: CaseReducer<State, PayloadAction<{ uiState: UiState }>> = (draft, action) => {
  const { uiState } = action.payload

  draft.uiState = uiState
}

const fetchBannersSuccess: CaseReducer<State, PayloadAction<{ banners: BannersModel }>> = (
  draft,
  action,
) => {
  const { banners } = action.payload

  draft.uiState = UiState.Success
  draft.banners = banners
}

const setBanners: CaseReducer<State, PayloadAction<{ banners: BannersModel }>> = (
  draft,
  action,
) => {
  const { banners } = action.payload

  draft.banners = banners
}

const bannersSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    changeUiState,
    fetchBannersSuccess,
    setBanners,
  },
})

export const { actions } = bannersSlice
export const plug = { [stateName]: bannersSlice.reducer }
export default bannersSlice.reducer

import { MultiVariantsOnboardingModalDto, SingleStepOnboardingModalDto } from 'types/dtos'
import { MultiVariantsOnboardingModalModel, SingleStepOnboardingModalModel } from 'types/models'

export const transformMultiVariantsOnboardingModal = ({
  name,
  actions,
  steps,
}: MultiVariantsOnboardingModalDto): MultiVariantsOnboardingModalModel => ({
  name,
  actions,
  steps: steps.map(step => ({
    name: step.name,
    title: step.title,
    body: step.body,
    imageUrl: step.image_url,
    video: step.video && {
      vertical: {
        formats: step.video.vertical.formats,
        imageUrl: step.video.vertical.image_url,
      },
    },
    videoDark: step.video_dark && {
      vertical: {
        formats: step.video_dark.vertical.formats,
        imageUrl: step.video_dark.vertical.image_url,
      },
    },
  })),
})

export const transformSingleStepOnboardingModal = ({
  name,
  title,
  sections,
  actions,
  ab_test,
}: SingleStepOnboardingModalDto): SingleStepOnboardingModalModel => ({
  name,
  title,
  sections: sections?.map(section => ({
    title: section.title,
    body: section.body,
    imageUrl: section.image_url,
  })),
  actions,
  abTest: ab_test,
})

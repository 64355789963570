import { Sagas } from './types'

import conversationSagas from './conversation/sagas'
import { sagas as checkoutSagas } from './checkout'
import bannersSagas from './banners/sagas'
import systemConfigurationSagas from './system-configuration/sagas'

export const getStaticSagas = (): Sagas => []

export const getCoreSagas = (): Sagas => [
  conversationSagas(),
  checkoutSagas(),
  bannersSagas(),
  systemConfigurationSagas(),
]

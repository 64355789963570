import { takeEvery, put, apply } from 'redux-saga/effects'
import { call, select } from 'typed-redux-saga'

import { transformTransaction } from 'data/transformers/transaction'

import * as api from 'data/api'
import { HttpStatus } from 'data/api/response-codes'

import { tracker } from '_libs/common/tracker'
import { abTestExposeEvent } from '_libs/common/event-tracker/events'

import { getAbTestByName } from 'state/ab-tests/selectors'
import { getExposee } from 'state/session/selectors'

import { AbTestVariant } from 'constants/abtest'

import * as statelessActions from './actions'
import { actions } from './slice'
import { transformConversationResponse } from './transformers'
import { getConversation as selectConversation } from './selectors'

export function* getConversation({
  payload: { conversationId, fromPostReply = false },
}: ReturnType<typeof statelessActions.getConversationRequest>) {
  yield put(actions.getConversationUiPending({ fromPostReply }))

  if (Number.isNaN(Number(conversationId))) {
    // TODO: backend returns 200 when passed a string. Should be fixed in backend
    yield put(actions.getConversationUiFailure({ error: null, status: HttpStatus.NotFound }))

    return
  }

  const response = yield* call(api.getConversation, conversationId)
  const conversation = yield* select(selectConversation)
  const exposee = yield* select(getExposee)
  const firstTimeListerEducationAbTest = yield* select(
    getAbTestByName('first_time_lister_phishing_education'),
  )

  // Braze conversations are updated synchronously, hence we need to avoid overriding Braze conversation in store
  if (conversation && !fromPostReply) return

  if ('errors' in response) {
    yield put(
      actions.getConversationUiFailure({
        error: response.errors[0]?.value || null,
        status: response.status,
      }),
    )

    return
  }

  const transformedConversation = transformConversationResponse(response.conversation)

  if (transformedConversation.transactionId) {
    yield put(
      actions.fetchTransactionRequest({ transactionId: transformedConversation.transactionId }),
    )
  }

  yield put(
    actions.getConversationSuccess({
      conversation: transformedConversation,
      status: response.status,
    }),
  )

  if (transformedConversation.isFirstTimeListerEducationRequired) {
    if (!firstTimeListerEducationAbTest) return

    const exposeEvent = abTestExposeEvent({ ...exposee, ...firstTimeListerEducationAbTest })

    yield apply(tracker, tracker.track, [exposeEvent])

    if (firstTimeListerEducationAbTest.variant !== AbTestVariant.Off) {
      yield put(actions.setFirstTimeListerEducationModalVisible({ isVisible: true }))
    }
  }
}

export function* createItemConversationThread({
  payload: { itemId, sellerId, initiator },
}: ReturnType<typeof actions.createItemConversationThreadRequest>) {
  const response = yield* call(api.createItemConversationThread, {
    itemId,
    initiator,
    receiverId: sellerId,
  })

  if ('errors' in response) {
    yield put(
      actions.createItemConversationThreadFailure({ error: response.errors[0]?.value || null }),
    )

    return
  }

  if (!('conversation' in response)) return

  const conversation = transformConversationResponse(response.conversation)

  yield put(actions.createItemConversationThreadSuccess({ conversation }))
}

export function* fetchTransaction({ payload }: ReturnType<typeof actions.fetchTransactionRequest>) {
  const response = yield* call(api.getTransaction, { id: payload.transactionId })

  if ('errors' in response) {
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    yield put(actions.fetchTransactionRequestFailure({ error: response?.errors?.[0]!?.value }))

    return
  }

  const transaction = transformTransaction(response.transaction)

  yield put(actions.fetchTransactionRequestSuccess({ transaction }))
}

export default function* saga() {
  yield takeEvery(statelessActions.getConversationRequest, getConversation)
  yield takeEvery(actions.createItemConversationThreadRequest, createItemConversationThread)
  yield takeEvery(actions.fetchTransactionRequest, fetchTransaction)
}
